import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {DateTime} from "luxon";
import {ContractType} from "@/constants/EmploymentType";

export default class HireIndividualEmployee {
    public employerId: number | null = null;

    public branchIds: Array<number> = [];

    public workPlacesId: Array<number> = [];

    public isRemote = true

    public payType: PayType | null = null;

    public payPeriodType: PayPeriodType | null = null;

    public rate: number | null = null;

    public employeeId: number | null = null;

    public employmentStart: Date | null = null;

    public officer = false

    public contractType = ContractType.FULL_TIME;

    public zoneId = DateTime.local().zoneName
}