










































































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import {InputType} from "@/components/common/InputType";
import PersonDTO from "@/dto/person/PersonDTO";
import {ifValid, processError} from "@/utils/ComponentUtils";
import HireIndividualEmployee from "@/dto/payroll/HireIndividualEmployee";
import Workspaces from "@/state/Workspaces";
import StaffService from "@/services/EmployeeService";
import PersonSearch from "@/components/payroll/business/employments/employee/PersonSearch.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import BranchDto from "@/components/profile/branches/BranchDtos";
import CompanyService from "@/services/CompanyService";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import {PAY_PERIOD_TYPE_OPTIONS, PAY_TYPE_OPTIONS, PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import AddressDTO from "@/dto/AddressDTO";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {ContractType, contractTypeOptions} from "@/constants/EmploymentType";

@Component({
	methods: {
		contractTypeOptions: contractTypeOptions,
		STATE_LIST() {
			return STATE_LIST
		}
	},
	computed: {
		ContractType() {
			return ContractType
		},
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		},
		PAY_TYPE_OPTIONS() {
			return PAY_TYPE_OPTIONS
		},
		PayType() {
			return PayType
		},
		SelectOption() {
			return SelectOption
		},
		InputType() {
			return InputType
		}
	},
	components: {PortalRadio, PortalSelect, PortalCheckbox, BTPortalCollapse, PersonSearch, PortalInput, PortalDate}
})
export default class StaffRegistrationModal extends Vue {
	private isOpen1 = false;
	private isOpen2 = false;
	private hasError = false;

	private persons: Array<PersonDTO> = [];

	private selectedPerson: PersonDTO | null = null;

	private createPersonRequest: CreatePersonRequestDTO = new CreatePersonRequestDTO();

	private createStafferRequest = new HireIndividualEmployee();

	private branches: Array<BranchDto> = [];

	private showList = false;

	private successful = true;

	private message = "";

	@Prop({default: "Hire employee"})
	private title!: string;

	@Prop()
	private callback!: () => void

	private processSelect(person: PersonDTO) {
		this.createStafferRequest.employeeId = person.id;
	}

	public mounted(): void {
		this.createStafferRequest.employerId = Workspaces.getCurrent.id;
		this.loadBranches()
	}

	private loadBranches() {
		Application.startLoading()
		Promise.all([
			CompanyService.getHeadQuarter(Workspaces.getCurrent.id),
			CompanyService.getBranches(Workspaces.getCurrent.id)
		]).then(
			resArr => {
				let branches: Array<BranchDto> = [];
				if (resArr[0].data) {
					branches.push(resArr[0].data)
				}
				branches.push(...resArr[1].data)
				this.branches = branches;
				Application.stopLoading()
			}
		)
	}

	private registerStaffer() {
		this.successful = true;
		this.message = ""
		if (!this.createStafferRequest.employeeId) {
			Notifications.error("Select a contractor from the drop-down list")
			this.hasError = true;
			this.isOpen1 = true;
			this.successful = false;
		} else {
			ifValid(this, () => {
				Application.startLoading()
				this.createStafferRequest.rate = Number(this.createStafferRequest.rate!.toString().replaceAll(",", "."))
				StaffService.registerStaffer(this.createStafferRequest).then(
					() => {
						Application.stopLoading()
						this.$modal.hideAll();
						this.callback()
					},
					err => processError(err, this)
				)
			}, () => {
				this.hasError = true;
				this.isOpen2 = true;
				this.isOpen1 = true;
			})
		}
	}

	private buildWorkplaces(): Array<SelectOption> {
		let workplaces: Array<SelectOption> = [];
		this.createStafferRequest.branchIds
			.map(id => this.branches.find(it => it.id == id))
			.map(it => this.assembleAddresses(it!))
			.map(list => list.filter(address => !!address && !!address.fullAddress).map(address => SelectOption.builder().title(address!.fullAddress).value(address!.id).build()))
			.forEach(list => workplaces.push(...list))
		return workplaces;
	}

	private assembleAddresses(branch: BranchDto) {
		let res = [];
		if (branch.legalAddress && branch.legalAddress.fullAddress) {
			res.push(branch.legalAddress)
		}
		if (branch.actualAddress && branch.actualAddress.fullAddress) {
			res.push(branch.actualAddress)
		}
		if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
			res.push(branch.mailingAddress)
		}
		if (!AddressDTO.isEmpty(branch.registrationAgentAddress)) {
			res.push(branch.registrationAgentAddress)
		}
		return res;
	}
}
