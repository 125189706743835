

























































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import {debounce} from "typescript-debounce-decorator";
import PersonService from "@/services/PersonService";
import PersonDTO from "@/dto/person/PersonDTO";
import {InputType} from "@/components/common/InputType";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";

export type PersonOptionType = "ALL" | "FIRST_NAME" | "LAST_NAME" | "EMAIL" | "SSN"

@Component({
	computed: {
		InputType() {
			return InputType
		}
	},
	components: {PortalInput}
})
export default class PersonSearch extends Vue {

	@Prop({default: 'ALL'})
	private options!: PersonOptionType | Array<PersonOptionType>;

	@Prop({default: 8})
	private pageSize!: number

	@VModel({type: CreatePersonRequestDTO})
	private createReq!: CreatePersonRequestDTO;

	private selectedPerson: PersonDTO | null = null;

	private persons: Array<PersonDTO> = [];

	private showList = false;


	@debounce(500)
	public search(): void {
		PersonService.getByFilter(this.buildSearchDto()).then(
			ok => {
				this.persons = ok.data.data;
				this.showList = true;
			},
			err => {
				console.log(JSON.stringify(err))
			}
		)
	}

	buildSearchDto(): PersonFilterDTO {
		const filter = new PersonFilterDTO({pageSize: this.pageSize});
		filter.firstName = this.createReq.firstName;
		filter.lastName = this.createReq.lastName;
		filter.ssn = this.createReq.ssn;
		filter.email = this.createReq.email;
		return filter;
	}

	private selectPerson(p: PersonDTO): void {
		this.showList = false;
		this.createReq.firstName = p.firstName as string;
		this.createReq.lastName = p.lastName as string;
		this.createReq.ssn = p.ssn as string;
		this.createReq.email = p.email as string;
		this.$emit("change", p);
	}

}

