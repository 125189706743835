
































































































import {Component, Vue} from "vue-property-decorator";
import ContractorRegistrationModal
	from "@/components/payroll/business/employments/contractors/ContractorRegistractionModal.vue";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RouteNames from "@/router/RouteNames";
import {CounterpartyType} from "@/constants/CounterpartyType";
import {ContractorFilter} from "@/dto/payroll/Filters";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import ForeignContractorService
	from "@/components/payroll/business/employments/foreignContractors/ForeignContractorService";
import {IForeignContractor} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import ForeignContractorRegistrationModal
	from "@/components/payroll/business/employments/foreignContractors/ForeignContractorRegistrationModalDetails.vue";

const AppModule = namespace("App");

@Component({
	components: {PaginationComponent, PortalSelect, PortalInput},
	computed: {
		CounterpartyType() {
			return CounterpartyType
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		}
	}
})
export default class ForeignContractors extends Vue {
	private contractors: Array<IForeignContractor> = [];

	private message: string | null = null;

	private successful = false;

	private totalPages = 1;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.filter.employerId = this.$wss.getCurrent.id
		this.loadAllContractors();
	}

	private RouteNames = RouteNames;

	private filter: ContractorFilter = new ContractorFilter();

	private reset() {
		this.filter = new ContractorFilter();
		this.filter.employerId = this.$wss.getCurrent.id
		this.loadAllContractors();
	}

	private search() {
		this.loadAllContractors()
	}

	private buildOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(CounterpartyType.COMPANY).value(CounterpartyType.COMPANY).build(),
			SelectOption.builder().value(CounterpartyType.PERSON).value(CounterpartyType.PERSON).build()
		]
	}

	private loadAllContractors(): void {
		this.startLoading();
		ForeignContractorService.getAllByFilter(this.filter).then(
			res => {
				this.contractors = res.data.data
				this.totalPages = res.data.countOfPages
				this.stopLoading();
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private addContractor(): void {
		this.$modal.show(
			ForeignContractorRegistrationModal,
			{
				callback: () => this.loadAllContractors()
			},
			{
				overflow: "visible",
				height: "auto"
			}
		)
	}

	changePage(page: number){
		this.filter.pageNumber = page - 1;
		this.loadAllContractors();
	}
}

